import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './Subscription.css';
import appConfig from '../config/appConfig.json';

const Subscription = () => {
  const [user] = useAuthState(auth);
  const [canChat, setCanChat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(null);

  const checkPlanExpiry = async () => {
    if (!user) return;

    try {
      const userDoc = await getDoc(doc(firestore, 'users', user.uid));
      if (userDoc.exists()) {
        const { planExpiry, RemainingchatCount } = userDoc.data();
        const currentDate = new Date();
        let expiryDate;

        if (planExpiry && planExpiry._seconds) {
          expiryDate = new Date(planExpiry._seconds * 1000);
        } else {
          console.error('Invalid planExpiry format:', planExpiry);
          setCanChat(false);
          return;
        }

        if (expiryDate <= currentDate || RemainingchatCount <= 0) {
          alert('Your plan has expired or you have no remaining chats. Please purchase a new plan.');
          setCanChat(false);
        } else {
          setCanChat(true);
        }
      } else {
        setCanChat(false);
      }
    } catch (error) {
      console.error('Error checking plan status: ', error);
      setCanChat(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      checkPlanExpiry();
    }
  }, [user]);

  const handlePlanPurchase = async (planType, planId, duration, chats) => {
    setLoadingButton(planType);
    try {
      const { data } = await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/plan/purchase`, {
        planType,
        amount: planId,
        duration,
        chats,
        userId: user.uid,
      });

      const options = {
        amount: planId * 100,
        order_id: data.orderId,
        handler: async (response) => {
          try {
            await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/plan/confirm`, {
              userId: user.uid,
              razorpay_payment_id: response.razorpay_payment_id,
              planType,
              duration,
              chats,
            });
            alert('Plan purchased successfully!');
            setCanChat(true);
          } catch (error) {
            console.error('Error confirming purchase:', error);
            alert('Plan purchase failed. Please try again.');
          } finally {
            setLoadingButton(null);
          }
        },
        modal: {
          ondismiss: () => {
            setLoadingButton(null);
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Error initiating purchase', error);
      alert('Purchase initiation failed.');
      setLoadingButton(null);
    }
  };

  return (
    <section className="card pricing_container">
      <div className="card__container grid">
        {[
          {
            type: 'Daily',
            subtitle: 'QUICK INSIGHT',
            price: 29,
            old_price: 59,
            duration: 1,
            chats: 10,
            discount: '50%',
            features: [
              '10 questions answered by astrologer',
              '24 hour plan duration',
              '24/7 chat available'
            ],
          },
          {
            type: 'Weekly',
            subtitle: 'DETAILED GUIDANCE',
            price: 99,
            old_price: 249,
            duration: 1,
            chats: 100,
            discount: '60%',
            features: [
              '100 questions answered by astrologer',
              '7 day plan duration',
              '24/7 chat available',
            ],
          },
          {
            type: 'Monthly',
            subtitle: 'BEST VALUE',
            price: 199,
            old_price: 799,
            duration: 1,
            chats: 1000,
            discount: '75%',
            features: [
              '1000 questions answered by astrologer',
              '30 day plan duration',
              '24/7 chat available',
            ],
          },
        ].map((plan) => (
          <article key={plan.type} className="card__content grid">
            <div className="card__pricing">
              <div className="card__pricing-number">
                <p className="card__pricing-symbol">{plan.discount} Off</p>
              </div>
            </div>
            <header className="card__header">
              <h3 className="card__header-title">{plan.type} Plan</h3>
              <span className="card__header-subtitle">{plan.subtitle}</span>
              <h2 className="old-price">₹ {plan.old_price}</h2>
              <h1>₹ {plan.price} /{plan.type.toLowerCase()}</h1>
              <p>₹{(plan.price / plan.chats).toFixed(2)} per message</p>
            </header>
            <ul className="card__list grid">
              {plan.features.map((feature, index) => (
                <li key={index} className="card__list-item">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHklKgVSVpzQz7zMW5OfN7w8mYqOQOJMAlYw&s"
                    width="20"
                    alt=".tick"
                  />
                  <p>{feature}</p>
                </li>
              ))}
            </ul>
            <button
              className="card__button"
              onClick={() => handlePlanPurchase(plan.type, plan.price, plan.duration, plan.chats)}
              disabled={loadingButton === plan.type}
            >
              {loadingButton === plan.type ? (
                <span className="spinner"></span>
              ) : (
                `Buy ${plan.type} Plan`
              )}
            </button>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Subscription;
