import React, { useState } from 'react';
import './ReportForm.css';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import appConfig from '../config/appConfig.json';
import { firestore } from '../firebase';
import { doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
// import { getAuth } from 'firebase/auth';
import Nav from './Nav.js'
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


import { v4 as uuidv4 } from 'uuid';

const ReportForm = () => {

  const notify = () => toast.success("Report ID Copied");

  const navigate = useNavigate();
  const { reportName } = useParams();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    // phone: '',
    dateOfBirth: '',
    timeOfBirth: '',
    // gender: '',
    // country: 'India',
    // state: '',
    city: '',
    // pinCode: '',
    language: 'English',
  });
  const [errors, setErrors] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [generatedReportId, setGeneratedReportId] = useState('');

   const reportId = uuidv4();

  // Update form data
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Validate form data
  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      console.log(key)
      if (!formData[key]) {
        console.log("required")
        newErrors[key] = 'This field is required';
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleOpenPdf = () => {
    window.open('/Sample Personalized Life Report.pdf', '_blank');
  };  

  const getCookieValue = (name) => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };

  const handlePlanPurchase = async () => {
    if (!validateForm()) return;
  
    setLoadingButton(true);
  
    const planType = reportName.replace('-', ' ');
    const amount = reportName === 'life-report' ? 499 : 599;
  
    try {
      // Save the user data to Firestore before initiating payment
      // const userId = auth.currentUser?.uid || 'guest-user';
      // const reportId = `report_${new Date().getTime()}`; // Generate a unique ID for the report
  
      await setDoc(doc(firestore, 'reports', reportId), {
        reportId,
        planType,
        amount,
        saveTime: Timestamp.now(),
        reportDetails: formData,
        payment_status: 'pending', // Initial status
      });

      const fbc = getCookieValue('_fbc') || null; // Get _fbc value from cookies
      const fbp = getCookieValue('_fbp') || null; // Get _fbp value from cookies
      
      // Once data is saved, initiate the payment process
      const { data } = await axios.post(
        `${appConfig.REACT_APP_BACKEND_URL}/plan/report-purchase`,
        {
          planType,
          amount,
          reportId,
          fbc,
          fbp,
        }
      );
  
      const options = {
        amount: amount * 100, // Amount in paise
        currency: 'INR',
        name: 'Vedic Rashi',
        description: `Payment for ${planType}`,
        order_id: data.orderId,
        handler: async (response) => {
          try {
            // Update the saved document with payment details
            await updateDoc(
              doc(firestore, 'reports', reportId),
              {
                paymentId: response.razorpay_payment_id,
                paymentTime: Timestamp.now(),
                payment_status: 'completed', // Mark as completed after successful payment
              },
              { merge: true }
            );
            setGeneratedReportId(reportId); // Set the report ID to display in the popup
            setPopupVisible(true); // Show the popup
            // alert(`${planType} purchased successfully!`);
          } catch (error) {
            console.error('Error updating report details after payment:', error);
            alert('Failed to update report details. Please contact support.');
          } finally {
            // navigate('/report')
            setLoadingButton(false);
          }
        },
        modal: {
          ondismiss: () => {
            setLoadingButton(false);
          },
        },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error('Error during the process:', error);
      alert('An error occurred. Please try again.');
      setLoadingButton(false);
    }
  };  
  const copyReportId = () => {
    navigator.clipboard.writeText(generatedReportId).then(() => {
      notify();
      closePopup();
    });
  };
  
  const closePopup = () => {
    setPopupVisible(false);
    // navigate('/report/life-report'); // Navigate to another page if needed
  };
  
  return (
    <>
      <Nav />
      <div className="astro-form-container">
      <ToastContainer autoClose={2000} />
      <div className="astro-form-left">
          <h1 className="logo">Personalised {reportName.replace("-", " ")}</h1>
          {/* <ul>
            <li><img src='https://static.vecteezy.com/system/resources/thumbnails/017/350/123/small_2x/green-check-mark-icon-in-round-shape-design-png.png' alt='img' />Money</li>
            <li><img src='https://static.vecteezy.com/system/resources/thumbnails/017/350/123/small_2x/green-check-mark-icon-in-round-shape-design-png.png' alt='img' />Love</li>
            <li><img src='https://static.vecteezy.com/system/resources/thumbnails/017/350/123/small_2x/green-check-mark-icon-in-round-shape-design-png.png' alt='img' />Health</li>
            <li><img src='https://static.vecteezy.com/system/resources/thumbnails/017/350/123/small_2x/green-check-mark-icon-in-round-shape-design-png.png' alt='img' />Career</li>
            <li><img src='https://static.vecteezy.com/system/resources/thumbnails/017/350/123/small_2x/green-check-mark-icon-in-round-shape-design-png.png' alt='img' />Marriage</li>
            <li><img src='https://static.vecteezy.com/system/resources/thumbnails/017/350/123/small_2x/green-check-mark-icon-in-round-shape-design-png.png' alt='img' />Business</li>
          </ul> */}
          <p>Get your report in 24 hours</p>
          {/* <h2>Life Report</h2> */}
        {/* <p>
          Please provide your full name, date of birth, location of birth, gender and contact number. Rest assured,
          we do not require any other personal or sensitive details. It’s crucial
          to fill out this form accurately, as we’ll generate reports based on
          the information you submit. The report will be delivered to your
          registered email ID within the next 2 to 4 working days.
        </p> */}
        {/* <p>Contact Us: vedicrashiofficial@gmail.com Support Portal</p> */}
      </div>
      <div className="astro-form-right">
      <img src='/astro.png' alt='' className='report-astro-img'></img>
      <h3>Get lifetime Free subscription to unlimited 24/7 chat with astrologer.</h3>
        
      <button
            type="button"
            className="sample-report-underline-button"
            onClick={handleOpenPdf}
          >
            View Sample Report
          </button>

        
        <form className="astro-form" onSubmit={(e) => e.preventDefault()}>
          <label>Name (As Per Report)</label>
          <input
            type="text"
            name="fullName"
            placeholder="Full Name"
            value={formData.fullName}
            onChange={handleInputChange}
          />
          {errors.fullName && <p className="error">{errors.fullName}</p>}

          <label>Email</label>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <p className="error">{errors.email}</p>}

          {/* <label>Phone</label>
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            value={formData.phone}
            onChange={handleInputChange}
          />
          {errors.phone && <p className="error">{errors.phone}</p>} */}

          <label>Date of Birth</label>
          <input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleInputChange}
          />
          {errors.dateOfBirth && <p className="error">{errors.dateOfBirth}</p>}

          <label>Time of Birth</label>
          <input
            type="time"
            name="timeOfBirth"
            value={formData.timeOfBirth}
            onChange={handleInputChange}
          />
          {errors.timeOfBirth && <p className="error">{errors.timeOfBirth}</p>}

          {/* <label>Gender</label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          {errors.gender && <p className="error">{errors.gender}</p>} */}

          {/* <label>Country of Birth</label>
          <select
            name="country"
            value={formData.country}
            onChange={handleInputChange}
          >
            <option value="India">India</option>
            
          </select> */}

          {/* <label>State of Birth</label>
          <input
            type="text"
            name="state"
            placeholder="State of Birth"
            value={formData.state}
            onChange={handleInputChange}
          />
          {errors.state && <p className="error">{errors.state}</p>} */}

          <label>City of Birth</label>
          <input
            type="text"
            name="city"
            placeholder="City of Birth"
            value={formData.city}
            onChange={handleInputChange}
          />
          {errors.city && <p className="error">{errors.city}</p>}

          {/* <label>Pin Code</label>
          <input
            type="text"
            name="pinCode"
            placeholder="Pin Code"
            value={formData.pinCode}
            onChange={handleInputChange}
          />
          {errors.pinCode && <p className="error">{errors.pinCode}</p>} */}

          <label>Report Language</label>
          <select
            name="language"
            value={formData.language}
            onChange={handleInputChange}
          >
            <option value="English">English</option>
            <option value="Hindi">Hinglish (Hindi written in English)</option>
            <option value="Hindi">Hindi</option>
          </select>

          <button
            type="button"
            className="pay-button"
            onClick={handlePlanPurchase}
            disabled={loadingButton}
          >
            {loadingButton ? 'Processing...' : 'Pay ₹499 to continue'}
          </button>
        </form>
      </div>
      {/* Popup for Report ID */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2 className='popup-content-title'>Payment Successful!</h2>
            <video src='/payment-sucess-animation.webm' autoPlay muted className='payment-animation'></video>
            <p className='popup-content-des'>You will receive your {reportName.replace("-", " ")} within the next 24 hours at the email address you provided.</p>
            {/* <p>Please copy and save your Report ID to track your report status.</p> */}
            <p className='report-id'>
              <strong>Report ID:</strong> {generatedReportId} <i onClick={copyReportId} class="ri-file-copy-line copy-icon"></i>
            </p>
              <p>You can copy the report ID for further tracking and close the pop-up</p>
          </div>
        </div>
      )}
    </div>
    </>
    
  );
};

export default ReportForm;
