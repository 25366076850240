import React from 'react'
import { Link } from 'react-router-dom'
import '../components/Footer.css'

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="contain">
          <div className="col">
            <h1>About Us</h1>
            <ul>
              <li><Link to="/about">Our Story</Link></li>
              <li><Link to="/vision">Vision & Mission</Link></li>
              <li><Link to="/services">Astrology Services</Link></li>
              <li><Link to="/knowledge">Vedic Knowledge</Link></li>
              <li><Link to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div className="col">
            <h1>Services</h1>
            <ul>
              <li><Link to="/kundali-reading">Kundali Reading</Link></li>
              <li><Link to="/horoscope-matching">Horoscope Matching</Link></li>
              <li><Link to="/daily-horoscope">Daily Horoscope</Link></li>
              <li><Link to="/report">Astrological Reports</Link></li>
              
            </ul>
          </div>
          <div className="col">
            <h1>Account</h1>
            <ul>
              {/* <li><Link to="/signin">Sign In</Link></li> */}
              <li><Link to="/profile">My Profile</Link></li>
              <li><Link to="/subscriptions">Subscriptions</Link></li>
              <li><Link to="/order-history">Order History</Link></li>
              {/* <li><Link to="/logout">Logout</Link></li> */}
            </ul>
          </div>
          <div className="col">
            <h1>Resources</h1>
            <ul>
              <li><Link to="/blogs">Blogs</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
              <li><Link to="/feedback">Submit Feedback</Link></li>
              {/* <li><Link to="/glossary">Astrology Glossary</Link></li> */}
              {/* <li><Link to="/tools">Astrology Tools</Link></li> */}
              {/* <li><Link to="/vastu">Vastu Shastra</Link></li> */}
              {/* <li><Link to="/tarot-reading">Tarot Reading</Link></li> */}
            </ul>
          </div>
          <div className="col">
            <h1>Support</h1>
            <ul>
              <li><Link to="/tnc">Terms & Conditions</Link></li>
              <li><Link to="/privacy">Privacy Policy</Link></li>
              <li><Link to="/returns">Return & Refund Policy</Link></li>
            </ul>
          </div>
          <div className="col social">
            <ul>
              <li>
                <a href="https://www.facebook.com">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png" width="32" alt='.img' />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com">
                  <img src="https://png.pngtree.com/png-clipart/20230401/original/pngtree-three-dimensional-instagram-icon-png-image_9015419.png" width="32" alt='.img' />
                </a>
              </li>
              <li>
                <a href="https://www.twitter.com">
                  <img src="https://static.vecteezy.com/system/resources/previews/027/395/710/non_2x/twitter-brand-new-logo-3-d-with-new-x-shaped-graphic-of-the-world-s-most-popular-social-media-free-png.png" width="32" alt='.img' />
                </a>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
          <hr />
          <h1 className='footer-name'>VedicRashi</h1>
        </div>
      </div>
    </div>
  )
}

export default Footer
