import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div data-custom-class="body">
  <div>
    <strong>
      <span style={{ fontSize: 26 }}>
        <span data-custom-class="title">
          <bdt className="block-component" />
          <bdt className="question">PRIVACY POLICY</bdt>
          <bdt className="statement-end-if-in-editor" />
        </span>
      </span>
    </strong>
  </div>
  <div>
    <br />
  </div>
  <div>
    <span style={{ color: "rgb(127, 127, 127)" }}>
      <strong>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="subtitle">
            Last updated
            <bdt className="question">September 16, 2024</bdt>
          </span>
        </span>
      </strong>
    </span>
  </div>
  <div>
    <br />
  </div>
  <div>
    <br />
  </div>
  <div>
    <br />
  </div>
  <div style={{ lineHeight: "1.5" }}>
    <span style={{ color: "rgb(127, 127, 127)" }}>
      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
        <span data-custom-class="body_text">
          This Privacy Notice for
          <bdt className="question">
            Escenem Technologies (OPC) Pvt Ltd
            <bdt className="block-component" />
            (doing business as
            <bdt className="question">Vedic Rashi </bdt>
            )
            <bdt className="statement-end-if-in-editor" />
          </bdt>
          (
          <bdt className="block-component" />'<strong>we</strong>', '
          <strong>us</strong>', or '<strong>our</strong>'
          <bdt className="else-block" />
        </span>
        <span data-custom-class="body_text">
          ), describes how and why we might access, collect, store, use, and/or
          share (
          <bdt className="block-component" />'<strong>process</strong>'
          <bdt className="else-block" />
          ) your personal information when you use our services (
          <bdt className="block-component" />'<strong>Services</strong>'
          <bdt className="else-block" />
          ), including when you:
        </span>
      </span>
    </span>
    <span style={{ fontSize: 15 }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span data-custom-class="body_text">
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </span>
    </span>
  </div>
  <ul>
    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Visit our website
            <bdt className="block-component" />
            at
            <span style={{ color: "rgb(0, 58, 250)" }}>
              <bdt className="question">
                <a
                  href="https://vedicrashi.in"
                  target="_blank"
                  data-custom-class="link"
                >
                  https://vedicrashi.in
                </a>
              </bdt>
            </span>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt className="statement-end-if-in-editor">
                        , or any website of ours that links to this Privacy
                        Notice
                      </bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </li>
  </ul>
  <div>
    <bdt className="block-component">
      <span style={{ fontSize: 15 }}>
        <span style={{ fontSize: 15 }}>
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </bdt>
    <div style={{ lineHeight: "1.5" }}>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }} />
      </bdt>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15 }}>
          Use
          <bdt className="question">Vedic Rashi </bdt>.
          <bdt className="question">
            Vedic Rashi combines the ancient wisdom of Vedic astrology with the
            power of artificial intelligence to help you unlock the secrets of
            your Kundli instantly. Our advanced AI engine analyzes your birth
            chart and planetary positions to provide personalized insights,
            offering guidance on life’s most important aspects—career,
            relationships, health, and personal growth. Accessible, affordable,
            and easy to use, [Company Name] helps you understand your destiny in
            minutes, empowering you to make informed decisions and lead a more
            fulfilling life."
          </bdt>
        </span>
        <bdt className="statement-end-if-in-editor">
          <span style={{ fontSize: 15 }} />
        </bdt>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              Engage with us in other related ways, including any sales,
              marketing, or events
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span data-custom-class="body_text">
            <strong>Questions or concerns?&nbsp;</strong>Reading this Privacy
            Notice will help you understand your privacy rights and choices. We
            are responsible for making decisions about how your personal
            information is processed. If you do not agree with our policies and
            practices, please do not use our Services.
            <bdt className="block-component" />
            If you still have any questions or concerns, please contact us at
            <bdt className="question">vedicrashiofficial@gmail.com</bdt>.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <strong>
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
        </span>
      </strong>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>
            <em>
              This summary provides key points from our Privacy Notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using our&nbsp;
            </em>
          </strong>
        </span>
      </span>
      <a data-custom-class="link" href="#toc">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            <strong>
              <em>table of contents</em>
            </strong>
          </span>
        </span>
      </a>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>
            <em>&nbsp;below to find the section you are looking for.</em>
          </strong>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about&nbsp;
        </span>
      </span>
      <a data-custom-class="link" href="#personalinfo">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            personal information you disclose to us
          </span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>
            Do we process any sensitive personal information?&nbsp;
          </strong>
          Some of the information may be considered
          <bdt className="block-component" />
          'special' or 'sensitive'
          <bdt className="else-block" />
          in certain jurisdictions, for example your racial or ethnic origins,
          sexual orientation, and religious beliefs.
          <bdt className="block-component" />
          We do not process sensitive personal information.
          <bdt className="else-block" />
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>Do we collect any information from third parties?</strong>
          <bdt className="block-component" />
          We do not collect any information from third parties.
          <bdt className="else-block" />
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>How do we process your information?</strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about&nbsp;
        </span>
      </span>
      <a data-custom-class="link" href="#infouse">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            how we process your information
          </span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>
            In what situations and with which
            <bdt className="block-component" />
            types of
            <bdt className="statement-end-if-in-editor" />
            parties do we share personal information?
          </strong>
          We may share information in specific situations and with specific
          <bdt className="block-component" />
          categories of
          <bdt className="statement-end-if-in-editor" />
          third parties. Learn more about&nbsp;
        </span>
      </span>
      <a data-custom-class="link" href="#whoshare">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            when and with whom we share your personal information
          </span>
        </span>
      </a>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          .
          <bdt className="block-component" />
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>How do we keep your information safe?</strong> We have
          adequate
          <bdt className="block-component" />
          organisational
          <bdt className="else-block" />
          and technical processes and procedures in place to protect your
          personal information. However, no electronic transmission over the
          internet or information storage technology can be guaranteed to be
          100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other
          <bdt className="block-component" />
          unauthorised
          <bdt className="else-block" />
          third parties will not be able to defeat our security and improperly
          collect, access, steal, or modify your information. Learn more
          about&nbsp;
        </span>
      </span>
      <a data-custom-class="link" href="#infosafe">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            how we keep your information safe
          </span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <bdt className="statement-end-if-in-editor" />
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more
          about&nbsp;
        </span>
      </span>
      <a data-custom-class="link" href="#privacyrights">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">your privacy rights</span>
        </span>
      </a>
      <span data-custom-class="body_text">.</span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by
          <bdt className="block-component">submitting a&nbsp;</bdt>
        </span>
      </span>
      <a
        data-custom-class="link"
        href="https://app.termly.io/notify/2fe548b8-1f22-4633-8431-ce9f298fe72f"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">data subject access request</span>
        </span>
      </a>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <bdt className="block-component" />, or by contacting us. We will
          consider and act upon any request in accordance with applicable data
          protection laws.
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          Want to learn more about what we do with any information we
          collect?&nbsp;
        </span>
      </span>
      <a data-custom-class="link" href="#toc">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            Review the Privacy Notice in full
          </span>
        </span>
      </a>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">.</span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div id="toc" style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(0, 0, 0)" }}>
            <strong>
              <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
            </strong>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infocollect">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            1. WHAT INFORMATION DO WE COLLECT?
          </span>
        </a>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infouse">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            2. HOW DO WE PROCESS YOUR INFORMATION?
            <bdt className="block-component" />
          </span>
        </a>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(0, 58, 250)" }}>
          <a data-custom-class="link" href="#whoshare">
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
        </span>
        <span data-custom-class="body_text">
          <bdt className="block-component" />
          <span style={{ color: "rgb(127, 127, 127)" }}>
            <span style={{ color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#cookies">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </span>
        <bdt className="block-component" />
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <a data-custom-class="link" href="#ai">
        <span style={{ color: "rgb (0, 58, 250)" }}>
          5. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?
        </span>
      </a>
      <span style={{ fontSize: 15 }}>
        <bdt className="statement-end-if-in-editor" />
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="block-component" />
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#sociallogins">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            <span style={{ color: "rgb(0, 58, 250)" }}>
              <span style={{ color: "rgb(0, 58, 250)" }}>
                6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
              </span>
            </span>
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <bdt className="statement-end-if-in-editor" />
                  </span>
                </span>
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#inforetain">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <bdt className="block-component" />
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#infosafe">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </span>
        </a>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(89, 89, 89)" }}>
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span style={{ color: "rgb(0, 58, 250)" }}>
          <a data-custom-class="link" href="#privacyrights">
            9. WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <a data-custom-class="link" href="#DNT">
          <span style={{ color: "rgb(0, 58, 250)" }}>
            10. CONTROLS FOR DO-NOT-TRACK FEATURES
            <bdt className="block-component" />
          </span>
          <bdt className="block-component">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
        </a>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <a data-custom-class="link" href="#DNT">
        <bdt className="block-component">
          <span style={{ fontSize: 15 }} />
        </bdt>
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
        <bdt className="block-component" />
      </a>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <a data-custom-class="link" href="#DNT">
        <span style={{ fontSize: 15 }} />
      </a>
      <a data-custom-class="link" href="#policyupdates">
        <span style={{ color: "rgb(0, 58, 250)" }}>
          11. DO WE MAKE UPDATES TO THIS NOTICE?
        </span>
      </a>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <a data-custom-class="link" href="#contact">
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </span>
      </a>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <a data-custom-class="link" href="#request">
        <span style={{ color: "rgb(0, 58, 250)" }}>
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </span>
      </a>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div id="infocollect" style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(0, 0, 0)" }}>
        <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
          <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
            <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
              <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span data-custom-class="heading_1">
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </strong>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div id="personalinfo" style={{ lineHeight: "1.5" }}>
      <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
        <span style={{ fontSize: 15 }}>
          <strong>Personal information you disclose to us</strong>
        </span>
      </span>
    </div>
    <div>
      <div>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>&nbsp;</em>
                    </strong>
                    <em>
                      We collect personal information that you provide to us.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We collect personal information that you voluntarily provide to us
            when you
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
            </span>
            register on the Services,&nbsp;
          </span>
          <span style={{ fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
            <span data-custom-class="body_text">
              express an interest in obtaining information about us or our
              products and Services, when you participate in activities on the
              Services, or otherwise when you contact us.
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <bdt className="block-component" />
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>Personal Information Provided by You.</strong> The personal
            information that we collect depends on the context of your
            interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect
            may include the following:
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">names</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">email addresses</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">date of birth</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">time of birth</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">location of birth</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">contact preferences</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">debit/credit card numbers</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <bdt className="question">billing addresses</bdt>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="forloop-component" />
              </span>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <strong>Sensitive Information.</strong>
          <bdt className="block-component" />
          We do not process sensitive information.
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <bdt className="else-block" />
        </span>
      </span>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>Payment Data.</strong> We may collect data necessary to
            process your payment if you choose to make purchases, such as your
            payment instrument number, and the security code associated with
            your payment instrument. All payment data is handled and stored by
            <bdt className="forloop-component" />
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <bdt className="question">RazorPay</bdt>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                      <span data-custom-class="body_text">
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                          <bdt className="forloop-component" />
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  . You may find their privacy notice link(s) here:
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <bdt className="forloop-component" />
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                  <bdt className="block-component" />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "rgb(0, 58, 250)" }}>
                    <bdt className="question">
                      <a
                        href="https://razorpay.com/privacy-policy/"
                        target="_blank"
                        data-custom-class="link"
                      >
                        https://razorpay.com/privacy-policy/
                      </a>
                    </bdt>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span data-custom-class="body_text">
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)"
                                  }}
                                >
                                  <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <bdt className="forloop-component" />
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text">
                            .
                            <bdt className="block-component" />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <bdt className="statement-end-if-in-editor">
                  <bdt className="block-component" />
                </bdt>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>Social Media Login Data.&nbsp;</strong>We may provide you
            with the option to register with us using your existing social media
            account details, like your Facebook, X, or other social media
            account. If you choose to register in this way, we will collect
            certain profile information about you from the social media
            provider, as described in the section called
            <bdt className="block-component" />
            '
            <bdt className="else-block" />
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(0, 58, 250)" }}>
                    <a data-custom-class="link" href="#sociallogins">
                      HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </a>
                  </span>
                </span>
              </span>
            </span>
            <bdt className="block-component" />
            '
            <bdt className="else-block" />
            below.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ fontSize: 15 }}>
              <bdt className="statement-end-if-in-editor">
                <bdt className="statement-end-if-in-editor" />
              </bdt>
            </span>
          </span>
        </span>
      </span>
      <bdt className="block-component" />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
        <span style={{ fontSize: 15 }}>
          <strong>Information automatically collected</strong>
        </span>
      </span>
    </div>
    <div>
      <div>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:</em>
                    </strong>
                  </span>
                </span>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>&nbsp;</em>
                    </strong>
                    <em>
                      Some information — such as your Internet Protocol (IP)
                      address and/or browser and device characteristics — is
                      collected automatically when you visit our Services.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            We automatically collect certain information when you visit, use, or
            navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may
            include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language
            preferences, referring URLs, device name, country, location,
            information about how and when you use our Services, and other
            technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our
            internal analytics and reporting purposes.
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            Like many businesses, we also collect information through cookies
            and similar technologies.
            <bdt className="block-component" />
            <bdt className="block-component" />
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            The information we collect includes:
            <bdt className="block-component" />
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <em>Log and Usage Data.</em> Log and usage data is
              service-related, diagnostic, usage, and performance information
              our servers automatically collect when you access or use our
              Services and which we record in log files. Depending on how you
              interact with us, this log data may include your IP address,
              device information, browser type, and settings and information
              about your activity in the Services
              <span style={{ fontSize: 15 }}>&nbsp;</span>(such as the date/time
              stamps associated with your usage, pages and files viewed,
              searches, and other actions you take such as which features you
              use), device event information (such as system activity, error
              reports (sometimes called
              <bdt className="block-component" />
              'crash dumps'
              <bdt className="else-block" />
              ), and hardware settings).
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text" />
        </span>
      </bdt>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <em>Device Data.</em> We collect device data such as information
              about your computer, phone, tablet, or other device you use to
              access the Services. Depending on the device used, this device
              data may include information such as your IP address (or proxy
              server), device and application identification numbers, location,
              browser type, hardware model, Internet service provider and/or
              mobile carrier, operating system, and system configuration
              information.
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }}>
          <span data-custom-class="body_text" />
        </span>
      </bdt>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <em>Location Data.</em> We collect location data such as
              information about your device's location, which can be either
              precise or imprecise. How much information we collect depends on
              the type and settings of the device you use to access the
              Services. For example, we may use GPS and other technologies to
              collect geolocation data that tells us your current location
              (based on your IP address). You can opt out of allowing us to
              collect this information either by refusing access to the
              information or by disabling your Location setting on your device.
              However, if you choose to opt out, you may not be able to use
              certain aspects of the Services.
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div>
      <bdt className="block-component">
        <span style={{ fontSize: 15 }} />
      </bdt>
      <bdt className="statement-end-if-in-editor" />
      <bdt className="block-component">
        <span style={{ fontSize: 15 }} />
      </bdt>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <strong>
          <span data-custom-class="heading_2">Google API</span>
        </strong>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <br />
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          Our use of information received from Google APIs will adhere to&nbsp;
        </span>
      </span>
      <a
        data-custom-class="link"
        href="https://developers.google.com/terms/api-services-user-data-policy"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">
            Google API Services User Data Policy
          </span>
        </span>
      </a>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">, including the&nbsp;</span>
      </span>
      <a
        data-custom-class="link"
        href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
        rel="noopener noreferrer"
        target="_blank"
      >
        <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
          <span data-custom-class="body_text">Limited Use requirements</span>
        </span>
      </a>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">.</span>
        <br />
      </span>
    </div>
    <div>
      <span style={{ fontSize: 15 }}>
        <br />
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <bdt className="statement-end-if-in-editor">
        <span style={{ fontSize: 15 }} />
      </bdt>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <bdt className="statement-end-if-in-editor">
                      <bdt className="block-component" />
                    </bdt>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </span>
      <span style={{ fontSize: 15 }}>
        <span data-custom-class="body_text">
          <bdt className="block-component" />
        </span>
      </span>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div id="infouse" style={{ lineHeight: "1.5" }}>
      <span style={{ color: "rgb(127, 127, 127)" }}>
        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span data-custom-class="heading_1">
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                  </span>
                </strong>
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
    <div>
      <div style={{ lineHeight: "1.5" }}>
        <br />
      </div>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ color: "rgb(127, 127, 127)" }}>
          <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>In Short:&nbsp;</em>
                    </strong>
                    <em>
                      We process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and to comply with law. We
                      may also process your information for other purposes with
                      your consent.
                    </em>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </div>
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <br />
    </div>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <strong>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </strong>
            <bdt className="block-component" />
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>
                To facilitate account creation and authentication and otherwise
                manage user accounts.&nbsp;
              </strong>
              We may process your information so you can create and log in to
              your account, as well as keep your account in working order.
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
    </div>
    <ul>
      <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <strong>
                To deliver and facilitate delivery of services to the
                user.&nbsp;
              </strong>
              We may process your information to provide you with the requested
              service.
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <bdt className="statement-end-if-in-editor" />
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <div style={{ lineHeight: "1.5" }}>
      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span data-custom-class="body_text">
            <bdt className="block-component" />
          </span>
        </span>
      </span>
      <div style={{ lineHeight: "1.5" }}>
        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component" />
            </span>
          </span>
        </span>
        <div style={{ lineHeight: "1.5" }}>
          <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                <bdt className="block-component" />
              </span>
            </span>
          </span>
          <div style={{ lineHeight: "1.5" }}>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }} />
            </bdt>
          </div>
          <ul>
            <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      To send administrative information to you.&nbsp;
                    </strong>
                    We may process your information to send you details about
                    our products and services, changes to our terms and
                    policies, and other similar information.
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <bdt className="statement-end-if-in-editor" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: "1.5" }}>
            <bdt className="block-component">
              <span style={{ fontSize: 15 }} />
            </bdt>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }} />
              </bdt>
            </div>
            <ul>
              <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To
                        <bdt className="block-component" />
                        fulfil
                        <bdt className="else-block" />
                        and manage your orders.&nbsp;
                      </strong>
                      We may process your information to
                      <bdt className="block-component" />
                      fulfil
                      <bdt className="else-block" />
                      and manage your orders, payments, returns, and exchanges
                      made through the Services.
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)"
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)"
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)"
                                      }}
                                    >
                                      <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <bdt className="block-component">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text" />
                </span>
              </bdt>
              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
              </p>
              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
              </p>
              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                <bdt className="block-component" />
              </p>
              <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <strong>To request feedback.&nbsp;</strong>We may
                          process your information when necessary to request
                          feedback and to contact you about your use of our
                          Services.
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <bdt className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                <bdt className="block-component" />
              </p>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text" />
                  </span>
                </bdt>
              </div>
              <ul>
                <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To send you marketing and promotional
                        communications.&nbsp;
                      </strong>
                      We may process the personal information you send to us for
                      our marketing purposes, if this is in accordance with your
                      marketing preferences. You can opt out of our marketing
                      emails at any time. For more information, see
                      <bdt className="block-component" />
                      '
                      <bdt className="else-block" />
                    </span>
                  </span>
                  <a href="#privacyrights">
                    <span data-custom-class="link">
                      <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                        <span data-custom-class="link">
                          WHAT ARE YOUR PRIVACY RIGHTS?
                        </span>
                      </span>
                    </span>
                  </a>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <bdt className="block-component" />
                      '
                      <bdt className="else-block" />
                      below.
                    </span>
                    <bdt className="statement-end-if-in-editor">
                      <span data-custom-class="body_text" />
                    </bdt>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <bdt className="block-component">
                  <span style={{ fontSize: 15 }} />
                </bdt>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className="block-component">
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15 }}>
                      <bdt className="block-component">
                        <span data-custom-class="body_text" />
                      </bdt>
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <bdt className="block-component">
                        <span style={{ fontSize: 15 }}>
                          <span data-custom-class="body_text" />
                        </span>
                      </bdt>
                      <div style={{ lineHeight: "1.5" }}>
                        <bdt className="block-component">
                          <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text" />
                          </span>
                        </bdt>
                        <div style={{ lineHeight: "1.5" }}>
                          <bdt className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                          </bdt>
                          <div style={{ lineHeight: "1.5" }}>
                            <bdt className="block-component">
                              <span style={{ fontSize: 15 }}>
                                <span data-custom-class="body_text" />
                              </span>
                            </bdt>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                            </div>
                            <ul>
                              <li
                                data-custom-class="body_text"
                                style={{ lineHeight: "1.5" }}
                              >
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <strong>
                                      To evaluate and improve our Services,
                                      products, marketing, and your experience.
                                    </strong>{" "}
                                    We may process your information when we
                                    believe it is necessary to identify usage
                                    trends, determine the effectiveness of our
                                    promotional campaigns, and to evaluate and
                                    improve our Services, products, marketing,
                                    and your experience.
                                  </span>
                                </span>
                                <bdt className="statement-end-if-in-editor">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                              </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                            </div>
                            <ul>
                              <li
                                data-custom-class="body_text"
                                style={{ lineHeight: "1.5" }}
                              >
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <strong>To identify usage trends.</strong>{" "}
                                    We may process information about how you use
                                    our Services to better understand how they
                                    are being used so we can improve them.
                                  </span>
                                </span>
                                <bdt className="statement-end-if-in-editor">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                              </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                              <bdt className="block-component">
                                <span style={{ fontSize: 15 }}>
                                  <span data-custom-class="body_text" />
                                </span>
                              </bdt>
                              <div style={{ lineHeight: "1.5" }}>
                                <bdt className="block-component">
                                  <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text" />
                                  </span>
                                </bdt>
                                <div style={{ lineHeight: "1.5" }}>
                                  <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </bdt>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <bdt className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </bdt>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <bdt className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </bdt>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                        <bdt className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </bdt>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div
                                        id="whoshare"
                                        style={{ lineHeight: "1.5" }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(127, 127, 127)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)"
                                                }}
                                              >
                                                <span
                                                  id="control"
                                                  style={{
                                                    color: "rgb(0, 0, 0)"
                                                  }}
                                                >
                                                  <strong>
                                                    <span data-custom-class="heading_1">
                                                      3. WHEN AND WITH WHOM DO
                                                      WE SHARE YOUR PERSONAL
                                                      INFORMATION?
                                                    </span>
                                                  </strong>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <strong>
                                                <em>In Short:</em>
                                              </strong>
                                              <em>
                                                &nbsp;We may share information
                                                in specific situations described
                                                in this section and/or with the
                                                following
                                                <bdt className="block-component" />
                                                categories of
                                                <bdt className="statement-end-if-in-editor" />
                                                third parties.
                                              </em>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <strong>
                                              Vendors, Consultants, and Other
                                              Third-Party Service Providers.
                                            </strong>{" "}
                                            We may share your data with
                                            third-party vendors, service
                                            providers, contractors, or agents (
                                            <bdt className="block-component" />'
                                            <strong>third parties</strong>'
                                            <bdt className="else-block" />
                                            ) who perform services for us or on
                                            our behalf and require access to
                                            such information to do that work.
                                            <bdt className="block-component" />
                                            We have contracts in place with our
                                            third parties, which are designed to
                                            help safeguard your personal
                                            information. This means that they
                                            cannot do anything with your
                                            personal information unless we have
                                            instructed them to do it. They will
                                            also not share your personal
                                            information with any
                                            <bdt className="block-component" />
                                            organisation
                                            <bdt className="else-block" />
                                            apart from us. They also commit to
                                            pr
                                          </span>
                                          <span data-custom-class="body_text">
                                            otect the data they hold on our
                                            behalf and to retain it for the
                                            period we instruct.
                                            <bdt className="statement-end-if-in-editor" />
                                          </span>
                                          <bdt className="block-component" />
                                        </span>
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            The
                                            <bdt className="block-component" />
                                            categories of
                                            <bdt className="statement-end-if-in-editor" />
                                            third parties we may share personal
                                            information with are as follows:
                                          </span>
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                              <bdt className="forloop-component" />
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="question">
                                                  AI Platforms
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="question">
                                                  Data Analytics Services
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="question">
                                                  Data Storage Service Providers
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="question">
                                                  Sales &amp; Marketing Tools
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)"
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <bdt className="question">
                                                  Social Networks
                                                </bdt>
                                              </span>
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)"
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)"
                                            }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <bdt className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <bdt className="forloop-component">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)"
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)"
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)"
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)"
                                                                                          }}
                                                                                        >
                                                                                          <bdt className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </bdt>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </bdt>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </span>
                                        <bdt className="block-component" />
                                        <span style={{ fontSize: 15 }}>
                                          <bdt className="block-component" />
                                        </span>
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <bdt className="block-component" />
                                        <span data-custom-class="body_text" />
                                        <span data-custom-class="body_text" />
                                        <span data-custom-class="body_text" />
                                        <span data-custom-class="body_text" />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <br />
                                      </div>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            We
                                            <bdt className="block-component" />
                                            also
                                            <bdt className="statement-end-if-in-editor" />
                                            may need to share your personal
                                            information in the following
                                            situations:
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <strong>
                                                Business Transfers.
                                              </strong>{" "}
                                              We may share or transfer your
                                              information in connection with, or
                                              during negotiations of, any
                                              merger, sale of company assets,
                                              financing, or acquisition of all
                                              or a portion of our business to
                                              another company.
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                          </span>
                                        </span>
                                      </div>
                                      <ul>
                                        <li
                                          data-custom-class="body_text"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                              <strong>
                                                When we use Google Maps Platform
                                                APIs.
                                              </strong>{" "}
                                              We may share your information with
                                              certain Google Maps Platform APIs
                                              (e.g.
                                              <bdt className="block-component" />
                                              Google Maps API, Places API).
                                            </span>
                                            <span data-custom-class="body_text">
                                              <bdt className="block-component">
                                                {" "}
                                                Google Maps uses GPS, Wi-Fi, and
                                                cell towers to estimate your
                                                location. GPS is accurate to
                                                about 20 meters, while Wi-Fi and
                                                cell towers help improve
                                                accuracy when GPS signals are
                                                weak, like indoors. This data
                                                helps Google Maps provide
                                                directions, but it is not always
                                                perfectly precise.
                                              </bdt>
                                              <bdt className="block-component" />
                                              We obtain and store on your device
                                              (
                                              <bdt className="block-component" />
                                              'cache'
                                              <bdt className="else-block" />
                                              ) your location
                                              <bdt className="block-component" />
                                              . You may revoke your consent
                                              anytime by contacting us at the
                                              contact details provided at the
                                              end of this document.
                                              <bdt className="statement-end-if-in-editor" />
                                              <bdt className="block-component" />
                                            </span>
                                          </span>
                                        </li>
                                      </ul>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span style={{ fontSize: 15 }}>
                                          <bdt className="block-component">
                                            <span data-custom-class="body_text" />
                                          </bdt>
                                        </span>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <bdt className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </bdt>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </bdt>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </bdt>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                    fontSize: 15
                                                  }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <span data-custom-class="heading_1">
                                                              <bdt className="block-component" />
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="cookies"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              4. DO WE USE
                                                              COOKIES AND OTHER
                                                              TRACKING
                                                              TECHNOLOGIES?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <em>In Short:</em>
                                                      </strong>
                                                      <em>
                                                        &nbsp;We may use cookies
                                                        and other tracking
                                                        technologies to collect
                                                        and store your
                                                        information.
                                                      </em>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      We may use cookies and
                                                      similar tracking
                                                      technologies (like web
                                                      beacons and pixels) to
                                                      gather information when
                                                      you interact with our
                                                      Services. Some online
                                                      tracking technologies help
                                                      us maintain the security
                                                      of our Services
                                                      <bdt className="block-component" />
                                                      and your account
                                                      <bdt className="statement-end-if-in-editor" />
                                                      , prevent crashes, fix
                                                      bugs, save your
                                                      preferences, and assist
                                                      with basic site functions.
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      We also permit third
                                                      parties and service
                                                      providers to use online
                                                      tracking technologies on
                                                      our Services for analytics
                                                      and advertising, including
                                                      to help manage and display
                                                      advertisements, to tailor
                                                      advertisements to your
                                                      interests, or to send
                                                      abandoned shopping cart
                                                      reminders (depending on
                                                      your communication
                                                      preferences). The third
                                                      parties and service
                                                      providers use their
                                                      technology to provide
                                                      advertising about products
                                                      and services tailored to
                                                      your interests which may
                                                      appear either on our
                                                      Services or on other
                                                      websites.
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  />
                                                </bdt>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      Specific information about
                                                      how we use such
                                                      technologies and how you
                                                      can refuse certain cookies
                                                      is set out in our Cookie
                                                      Notice
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                          .
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  />
                                                </bdt>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    <span data-custom-class="heading_2">
                                                      Google Analytics
                                                    </span>
                                                  </strong>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    We may share your
                                                    information with Google
                                                    Analytics to track and
                                                    <bdt className="block-component" />
                                                    analyse
                                                    <bdt className="else-block" />
                                                    the use of the Services.
                                                    <bdt className="block-component" />
                                                    The Google Analytics
                                                    Advertising Features that we
                                                    may use include:
                                                    <bdt className="forloop-component" />
                                                    <bdt className="block-component" />
                                                    <bdt className="question">
                                                      Google Display Network
                                                      Impressions Reporting
                                                    </bdt>
                                                    <bdt className="block-component" />
                                                    <bdt className="forloop-component" />
                                                    .
                                                    <bdt className="statement-end-if-in-editor" />
                                                    To opt out of being tracked
                                                    by Google Analytics across
                                                    the Services, visit{" "}
                                                    <span
                                                      style={{
                                                        color: "rgb(0, 58, 250)"
                                                      }}
                                                    >
                                                      <a
                                                        data-custom-class="link"
                                                        href="https://tools.google.com/dlpage/gaoptout"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                      >
                                                        https://tools.google.com/dlpage/gaoptout
                                                      </a>
                                                      .
                                                    </span>
                                                    <bdt className="block-component" />
                                                    You can opt out of Google
                                                    Analytics Advertising
                                                    Features through{" "}
                                                    <span
                                                      style={{
                                                        color: "rgb(0, 58, 250)"
                                                      }}
                                                    >
                                                      <a
                                                        data-custom-class="link"
                                                        href="https://adssettings.google.com/"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                      >
                                                        Ads Settings
                                                      </a>
                                                    </span>{" "}
                                                    and Ad Settings for mobile
                                                    apps. Other opt out means
                                                    include{" "}
                                                    <span
                                                      style={{
                                                        color: "rgb(0, 58, 250)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rbg(0, 58, 250)"
                                                        }}
                                                      >
                                                        <a
                                                          data-custom-class="link"
                                                          href="http://optout.networkadvertising.org/"
                                                          rel="noopener noreferrer"
                                                          target="_blank"
                                                        >
                                                          http://optout.networkadvertising.org/
                                                        </a>
                                                      </span>
                                                    </span>{" "}
                                                    and{" "}
                                                    <span
                                                      style={{
                                                        color: "rgb(0, 58, 250)"
                                                      }}
                                                    >
                                                      <a
                                                        data-custom-class="link"
                                                        href="http://www.networkadvertising.org/mobile-choice"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                      >
                                                        http://www.networkadvertising.org/mobile-choice
                                                      </a>
                                                    </span>
                                                    .
                                                    <bdt className="statement-end-if-in-editor" />
                                                    For more information on the
                                                    privacy practices of Google,
                                                    please visit the{" "}
                                                    <span
                                                      style={{
                                                        color: "rgb(0, 58, 250)"
                                                      }}
                                                    >
                                                      <a
                                                        data-custom-class="link"
                                                        href="https://policies.google.com/privacy"
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                      >
                                                        Google Privacy &amp;
                                                        Terms page
                                                      </a>
                                                    </span>
                                                    .
                                                  </span>
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  />
                                                </bdt>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                                <span style={{ fontSize: 15 }}>
                                                  <bdt className="block-component" />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                id="ai"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    <span data-custom-class="heading_1">
                                                      5. DO WE OFFER ARTIFICIAL
                                                      INTELLIGENCE-BASED
                                                      PRODUCTS?
                                                    </span>
                                                  </strong>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    <em>
                                                      <span data-custom-class="body_text">
                                                        In Short:
                                                      </span>
                                                    </em>
                                                  </strong>
                                                  <em>
                                                    <span data-custom-class="body_text">
                                                      &nbsp;We offer products,
                                                      features, or tools powered
                                                      by artificial
                                                      intelligence, machine
                                                      learning, or similar
                                                      technologies.
                                                    </span>
                                                  </em>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    As part of our Services, we
                                                    offer products, features, or
                                                    tools powered by artificial
                                                    intelligence, machine
                                                    learning, or similar
                                                    technologies (collectively,
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                    AI Products
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                    ). These tools are designed
                                                    to enhance your experience
                                                    and provide you with
                                                    innovative solutions. The
                                                    terms in this Privacy Notice
                                                    govern your use of the AI
                                                    Products within our
                                                    Services.
                                                  </span>
                                                  <bdt className="block-component" />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    <span data-custom-class="body_text">
                                                      Use of AI Technologies
                                                    </span>
                                                  </strong>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    We provide the AI Products
                                                    through third-party service
                                                    providers (
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                    AI Service Providers
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                    ), including
                                                    <bdt className="forloop-component" />
                                                    <bdt className="block-component" />
                                                    <bdt className="question">
                                                      OpenAI
                                                    </bdt>
                                                    <bdt className="block-component" />
                                                    <bdt className="forloop-component" />
                                                    . As outlined in this
                                                    Privacy Notice, your input,
                                                    output, and personal
                                                    information will be shared
                                                    with and processed by these
                                                    AI Service Providers to
                                                    enable your use of our AI
                                                    Products for purposes
                                                    outlined in
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                    <bdt className="block-component" />
                                                  </span>
                                                  <a
                                                    data-custom-class="link"
                                                    href="#whoshare"
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(0, 58, 250)",
                                                        fontSize: 15
                                                      }}
                                                    >
                                                      WHEN AND WITH WHOM DO WE
                                                      SHARE YOUR PERSONAL
                                                      INFORMATION?
                                                    </span>
                                                  </a>
                                                </span>
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor" />
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                    You must not use the AI
                                                    Products in any way that
                                                    violates the terms or
                                                    policies of any AI Service
                                                    Provider.
                                                  </span>
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    <span data-custom-class="body_text">
                                                      Our AI Products
                                                    </span>
                                                  </strong>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    Our AI Products are designed
                                                    for the following functions:
                                                  </span>
                                                  <bdt className="forloop-component" />
                                                </span>
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <bdt className="question">
                                                      <span data-custom-class="body_text">
                                                        AI insights
                                                      </span>
                                                    </bdt>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div>
                                                <span style={{ fontSize: 15 }}>
                                                  <bdt className="forloop-component" />
                                                </span>
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <bdt className="question">
                                                      <span data-custom-class="body_text">
                                                        AI bots
                                                      </span>
                                                    </bdt>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div>
                                                <span style={{ fontSize: 15 }}>
                                                  <bdt className="forloop-component" />
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <strong>
                                                    <span data-custom-class="body_text">
                                                      How We Process Your Data
                                                      Using AI
                                                    </span>
                                                  </strong>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <br />
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    All personal information
                                                    processed using our AI
                                                    Products is handled in line
                                                    with our Privacy Notice and
                                                    our agreement with third
                                                    parties. This ensures high
                                                    security and safeguards your
                                                    personal information
                                                    throughout the process,
                                                    giving you peace of mind
                                                    about your data's safety.
                                                  </span>
                                                  <bdt className="block-component" />
                                                  <bdt className="statement-end-if-in-editor" />
                                                </span>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <bdt className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="sociallogins"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              6. HOW DO WE
                                                              HANDLE YOUR SOCIAL
                                                              LOGINS?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <em>In Short:&nbsp;</em>
                                                      </strong>
                                                      <em>
                                                        If you choose to
                                                        register or log in to
                                                        our Services using a
                                                        social media account, we
                                                        may have access to
                                                        certain information
                                                        about you.
                                                      </em>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      Our Services offer you the
                                                      ability to register and
                                                      log in using your
                                                      third-party social media
                                                      account details (like your
                                                      Facebook or X logins).
                                                      Where you choose to do
                                                      this, we will receive
                                                      certain profile
                                                      information about you from
                                                      your social media
                                                      provider. The profile
                                                      information we receive may
                                                      vary depending on the
                                                      social media provider
                                                      concerned, but will often
                                                      include your name, email
                                                      address, friends list, and
                                                      profile picture, as well
                                                      as other information you
                                                      choose to make public on
                                                      such a social media
                                                      platform.
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      We will use the
                                                      information we receive
                                                      only for the purposes that
                                                      are described in this
                                                      Privacy Notice or that are
                                                      otherwise made clear to
                                                      you on the relevant
                                                      Services. Please note that
                                                      we do not control, and are
                                                      not responsible for, other
                                                      uses of your personal
                                                      information by your
                                                      third-party social media
                                                      provider. We recommend
                                                      that you review their
                                                      privacy notice to
                                                      understand how they
                                                      collect, use, and share
                                                      your personal information,
                                                      and how you can set your
                                                      privacy preferences on
                                                      their sites and apps.
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                      </span>
                                                                      <bdt className="block-component">
                                                                        <span data-custom-class="body_text">
                                                                          <bdt className="block-component" />
                                                                        </span>
                                                                      </bdt>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="inforetain"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              7. HOW LONG DO WE
                                                              KEEP YOUR
                                                              INFORMATION?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <em>In Short:&nbsp;</em>
                                                      </strong>
                                                      <em>
                                                        We keep your information
                                                        for as long as necessary
                                                        to
                                                        <bdt className="block-component" />
                                                        fulfil
                                                        <bdt className="else-block" />
                                                        the purposes outlined in
                                                        this Privacy Notice
                                                        unless otherwise
                                                        required by law.
                                                      </em>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      We will only keep your
                                                      personal information for
                                                      as long as it is necessary
                                                      for the purposes set out
                                                      in this Privacy Notice,
                                                      unless a longer retention
                                                      period is required or
                                                      permitted by law (such as
                                                      tax, accounting, or other
                                                      legal requirements).
                                                      <bdt className="block-component" />
                                                      No purpose in this notice
                                                      will require us keeping
                                                      your personal information
                                                      for longer than
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <bdt className="block-component" />
                                                      the period of time in
                                                      which users have an
                                                      account with us
                                                      <bdt className="block-component" />
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <bdt className="else-block" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                      .
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      When we have no ongoing
                                                      legitimate business need
                                                      to process your personal
                                                      information, we will
                                                      either delete or
                                                      <bdt className="block-component" />
                                                      anonymise
                                                      <bdt className="else-block" />
                                                      such information, or, if
                                                      this is not possible (for
                                                      example, because your
                                                      personal information has
                                                      been stored in backup
                                                      archives), then we will
                                                      securely store your
                                                      personal information and
                                                      isolate it from any
                                                      further processing until
                                                      deletion is possible.
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="infosafe"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              8. HOW DO WE KEEP
                                                              YOUR INFORMATION
                                                              SAFE?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <em>In Short:&nbsp;</em>
                                                      </strong>
                                                      <em>
                                                        We aim to protect your
                                                        personal information
                                                        through a system of
                                                        <bdt className="block-component" />
                                                        organisational
                                                        <bdt className="else-block" />
                                                        and technical security
                                                        measures.
                                                      </em>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      We have implemented
                                                      appropriate and reasonable
                                                      technical and
                                                      <bdt className="block-component" />
                                                      organisational
                                                      <bdt className="else-block" />
                                                      security measures designed
                                                      to protect the security of
                                                      any personal information
                                                      we process. However,
                                                      despite our safeguards and
                                                      efforts to secure your
                                                      information, no electronic
                                                      transmission over the
                                                      Internet or information
                                                      storage technology can be
                                                      guaranteed to be 100%
                                                      secure, so we cannot
                                                      promise or guarantee that
                                                      hackers, cybercriminals,
                                                      or other
                                                      <bdt className="block-component" />
                                                      unauthorised
                                                      <bdt className="else-block" />
                                                      third parties will not be
                                                      able to defeat our
                                                      security and improperly
                                                      collect, access, steal, or
                                                      modify your information.
                                                      Although we will do our
                                                      best to protect your
                                                      personal information,
                                                      transmission of personal
                                                      information to and from
                                                      our Services is at your
                                                      own risk. You should only
                                                      access the Services within
                                                      a secure environment.
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <bdt className="statement-end-if-in-editor" />
                                                      </span>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="privacyrights"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              9. WHAT ARE YOUR
                                                              PRIVACY RIGHTS?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <em>In Short:</em>
                                                      </strong>
                                                      <em>
                                                        &nbsp;
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <em>
                                                                <bdt className="block-component" />
                                                              </em>
                                                            </span>
                                                          </span>
                                                          &nbsp;
                                                        </span>
                                                        You may review, change,
                                                        or terminate your
                                                        account at any time,
                                                        depending on your
                                                        country, province, or
                                                        state of residence.
                                                      </em>
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <bdt className="block-component">
                                                            <bdt className="block-component" />
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="withdrawconsent"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <u>
                                                          Withdrawing your
                                                          consent:
                                                        </u>
                                                      </strong>{" "}
                                                      If we are relying on your
                                                      consent to process your
                                                      personal information,
                                                      <bdt className="block-component" />
                                                      which may be express
                                                      and/or implied consent
                                                      depending on the
                                                      applicable law,
                                                      <bdt className="statement-end-if-in-editor" />
                                                      you have the right to
                                                      withdraw your consent at
                                                      any time. You can withdraw
                                                      your consent at any time
                                                      by contacting us by using
                                                      the contact details
                                                      provided in the section
                                                      <bdt className="block-component" />
                                                      '
                                                      <bdt className="else-block" />
                                                    </span>
                                                  </span>
                                                </span>
                                                <a
                                                  data-custom-class="link"
                                                  href="#contact"
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(0, 58, 250)"
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(0, 58, 250)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        HOW CAN YOU CONTACT US
                                                        ABOUT THIS NOTICE?
                                                      </span>
                                                    </span>
                                                  </span>
                                                </a>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                      '
                                                      <bdt className="else-block" />
                                                      below
                                                      <bdt className="block-component" />
                                                      .
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    However, please note that
                                                    this will not affect the
                                                    lawfulness of the processing
                                                    before its withdrawal nor,
                                                    <bdt className="block-component" />
                                                    when applicable law allows,
                                                    <bdt className="statement-end-if-in-editor" />
                                                    will it affect the
                                                    processing of your personal
                                                    information conducted in
                                                    reliance on lawful
                                                    processing grounds other
                                                    than consent.
                                                    <bdt className="block-component" />
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <strong>
                                                      <u>
                                                        Opting out of marketing
                                                        and promotional
                                                        communications:
                                                      </u>
                                                    </strong>
                                                    <strong>&nbsp;</strong>You
                                                    can unsubscribe from our
                                                    marketing and promotional
                                                    communications at any time
                                                    by
                                                    <bdt className="block-component" />
                                                    clicking on the unsubscribe
                                                    link in the emails that we
                                                    send,
                                                    <bdt className="statement-end-if-in-editor" />
                                                    <bdt className="block-component" />
                                                    <bdt className="block-component" />
                                                    or by contacting us using
                                                    the details provided in the
                                                    section
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                  </span>
                                                </span>
                                                <a
                                                  data-custom-class="link"
                                                  href="#contact"
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(0, 58, 250)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      HOW CAN YOU CONTACT US
                                                      ABOUT THIS NOTICE?
                                                    </span>
                                                  </span>
                                                </a>
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="block-component" />
                                                    '
                                                    <bdt className="else-block" />
                                                    below. You will then be
                                                    removed from the marketing
                                                    lists. However, we may still
                                                    communicate with you — for
                                                    example, to send you
                                                    service-related messages
                                                    that are necessary for the
                                                    administration and use of
                                                    your account, to respond to
                                                    service requests, or for
                                                    other non-marketing
                                                    purposes.
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="heading_2">
                                                    <strong>
                                                      Account Information
                                                    </strong>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    If you would at any time
                                                    like to review or change the
                                                    information in your account
                                                    or terminate your account,
                                                    you can:
                                                    <bdt className="forloop-component" />
                                                  </span>
                                                </span>
                                              </div>
                                              <ul>
                                                <li
                                                  data-custom-class="body_text"
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <bdt className="question">
                                                        Contact us using the
                                                        contact information
                                                        provided.
                                                      </bdt>
                                                    </span>
                                                  </span>
                                                </li>
                                              </ul>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <bdt className="forloop-component" />
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    Upon your request to
                                                    terminate your account, we
                                                    will deactivate or delete
                                                    your account and information
                                                    from our active databases.
                                                    However, we may retain some
                                                    information in our files to
                                                    prevent fraud, troubleshoot
                                                    problems, assist with any
                                                    investigations, enforce our
                                                    legal terms and/or comply
                                                    with applicable legal
                                                    requirements.
                                                  </span>
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)"
                                                                    }}
                                                                  >
                                                                    <bdt className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <strong>
                                                        <u>
                                                          Cookies and similar
                                                          technologies:
                                                        </u>
                                                      </strong>{" "}
                                                      Most Web browsers are set
                                                      to accept cookies by
                                                      default. If you prefer,
                                                      you can usually choose to
                                                      set your browser to remove
                                                      cookies and to reject
                                                      cookies. If you choose to
                                                      remove cookies or reject
                                                      cookies, this could affect
                                                      certain features or
                                                      services of our Services.
                                                      <bdt className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="block-component" />
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)"
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </bdt>
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span data-custom-class="body_text">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    If you have questions or
                                                    comments about your privacy
                                                    rights, you may email us at
                                                    <bdt className="question">
                                                      vedicrashiofficial@gmail.com
                                                    </bdt>
                                                    .
                                                  </span>
                                                </span>
                                                <bdt className="statement-end-if-in-editor">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </bdt>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="DNT"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              10. CONTROLS FOR
                                                              DO-NOT-TRACK
                                                              FEATURES
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      Most web browsers and some
                                                      mobile operating systems
                                                      and mobile applications
                                                      include a Do-Not-Track (
                                                      <bdt className="block-component" />
                                                      'DNT'
                                                      <bdt className="else-block" />
                                                      ) feature or setting you
                                                      can activate to signal
                                                      your privacy preference
                                                      not to have data about
                                                      your online browsing
                                                      activities monitored and
                                                      collected. At this stage,
                                                      no uniform technology
                                                      standard for
                                                      <bdt className="block-component" />
                                                      recognising
                                                      <bdt className="else-block" />
                                                      and implementing DNT
                                                      signals has been
                                                      <bdt className="block-component" />
                                                      finalised
                                                      <bdt className="else-block" />
                                                      . As such, we do not
                                                      currently respond to DNT
                                                      browser signals or any
                                                      other mechanism that
                                                      automatically communicates
                                                      your choice not to be
                                                      tracked online. If a
                                                      standard for online
                                                      tracking is adopted that
                                                      we must follow in the
                                                      future, we will inform you
                                                      about that practice in a
                                                      revised version of this
                                                      Privacy Notice.
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  />
                                                </bdt>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                    </span>
                                                  </span>
                                                </span>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  />
                                                </bdt>
                                                <bdt className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  />
                                                </bdt>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="policyupdates"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              11. DO WE MAKE
                                                              UPDATES TO THIS
                                                              NOTICE?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <em>
                                                        <strong>
                                                          In Short:&nbsp;
                                                        </strong>
                                                        Yes, we will update this
                                                        notice as necessary to
                                                        stay compliant with
                                                        relevant laws.
                                                      </em>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      We may update this Privacy
                                                      Notice from time to time.
                                                      The updated version will
                                                      be indicated by an updated
                                                      <bdt className="block-component" />
                                                      'Revised'
                                                      <bdt className="else-block" />
                                                      date at the top of this
                                                      Privacy Notice. If we make
                                                      material changes to this
                                                      Privacy Notice, we may
                                                      notify you either by
                                                      prominently posting a
                                                      notice of such changes or
                                                      by directly sending you a
                                                      notification. We encourage
                                                      you to review this Privacy
                                                      Notice frequently to be
                                                      informed of how we are
                                                      protecting your
                                                      information.
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="contact"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              12. HOW CAN YOU
                                                              CONTACT US ABOUT
                                                              THIS NOTICE?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      If you have questions or
                                                      comments about this
                                                      notice, you may
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                          fontSize: 15
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component">
                                                            <bdt className="block-component" />
                                                          </bdt>
                                                          email us at
                                                          <bdt className="question">
                                                            vedicrashiofficial@gmail.com
                                                            or&nbsp;
                                                          </bdt>
                                                          <bdt className="statement-end-if-in-editor">
                                                            <bdt className="block-component" />
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            contact us by post
                                                            at:
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              color:
                                                                "rgb(89, 89, 89)"
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="question">
                                                                Escenem
                                                                Technologies
                                                                (OPC) Pvt Ltd
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <span data-custom-class="body_text">
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="question">
                                                      Ashoka city, Dampier
                                                      Nagar, Mathura, Uttar
                                                      Pradesh 281001, India
                                                    </bdt>
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <bdt className="question">
                                                      Mathura
                                                    </bdt>
                                                    <span
                                                      style={{
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <bdt className="block-component" />
                                                        ,
                                                        <bdt className="question">
                                                          Uttar Pradesh
                                                        </bdt>
                                                        <bdt className="statement-end-if-in-editor" />
                                                        <bdt className="block-component" />
                                                        <bdt className="question">
                                                          281001
                                                        </bdt>
                                                        <bdt className="statement-end-if-in-editor" />
                                                        <bdt className="block-component" />
                                                        <bdt className="block-component" />
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="block-component" />
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="question">
                                                      India
                                                    </bdt>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                fontSize: 15
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)"
                                                                  }}
                                                                >
                                                                  <bdt className="statement-end-if-in-editor">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)"
                                                                          }}
                                                                        >
                                                                          <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </bdt>
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)"
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15
                                                                  }}
                                                                >
                                                                  <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                  <span data-custom-class="body_text">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)"
                                                          }}
                                                        >
                                                          <bdt className="statement-end-if-in-editor">
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)"
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <bdt className="block-component">
                                                                    <bdt className="block-component" />
                                                                  </bdt>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </bdt>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      />
                                                    </bdt>
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <bdt className="statement-end-if-in-editor">
                                                                <bdt className="block-component" />
                                                              </bdt>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                id="request"
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(127, 127, 127)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "rgb(89, 89, 89)",
                                                      fontSize: 15
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        fontSize: 15,
                                                        color: "rgb(89, 89, 89)"
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)"
                                                        }}
                                                      >
                                                        <span
                                                          id="control"
                                                          style={{
                                                            color:
                                                              "rgb(0, 0, 0)"
                                                          }}
                                                        >
                                                          <strong>
                                                            <span data-custom-class="heading_1">
                                                              13. HOW CAN YOU
                                                              REVIEW, UPDATE, OR
                                                              DELETE THE DATA WE
                                                              COLLECT FROM YOU?
                                                            </span>
                                                          </strong>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <br />
                                              </div>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "rgb(89, 89, 89)"
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                      color: "rgb(89, 89, 89)"
                                                    }}
                                                  >
                                                    <span data-custom-class="body_text">
                                                      <bdt className="block-component" />
                                                      Based on the applicable
                                                      laws of your country
                                                      <bdt className="block-component" />
                                                      , you may
                                                      <bdt className="else-block">
                                                        <bdt className="block-component">
                                                          have the right to
                                                          request access to the
                                                          personal information
                                                          we collect from you,
                                                          details about how we
                                                          have processed it,
                                                          correct inaccuracies,
                                                          or delete your
                                                          personal information.
                                                          You may also have the
                                                          right to
                                                          <bdt className="block-component" />
                                                          withdraw your consent
                                                          to our processing of
                                                          your personal
                                                          information. These
                                                          rights may be limited
                                                          in some circumstances
                                                          by applicable law. To
                                                          request to review,
                                                          update, or delete your
                                                          personal information,
                                                          please
                                                          <bdt className="block-component" />
                                                          fill out and submit
                                                          a&nbsp;
                                                        </bdt>
                                                      </bdt>
                                                    </span>
                                                    <span
                                                      style={{
                                                        color: "rgb(0, 58, 250)"
                                                      }}
                                                    >
                                                      <span data-custom-class="body_text">
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(0, 58, 250)",
                                                            fontSize: 15
                                                          }}
                                                        >
                                                          <a
                                                            data-custom-class="link"
                                                            href="https://app.termly.io/notify/2fe548b8-1f22-4633-8431-ce9f298fe72f"
                                                            rel="noopener noreferrer"
                                                            target="_blank"
                                                          >
                                                            data subject access
                                                            request
                                                          </a>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    <bdt className="block-component">
                                                      <span data-custom-class="body_text" />
                                                    </bdt>
                                                  </span>
                                                </span>
                                                <span data-custom-class="body_text">
                                                  .
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default PrivacyPolicy