import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, googleProvider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import './Authentication.css'; // Import the CSS

const Authentication = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  

  const handleGoogleLogin = async () => {
    setButtonLoading(true);
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/chat?login=complete'); // Redirect to chat after successful login
    } catch (error) {
      console.error('Error signing in with Google:', error);
      alert('Error signing in with Google');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-content">
        <div className="astrology-image"></div>
        <h1>
          Discover the empowering guidance <strong>Vedic Rashi</strong> holds for your life
        </h1>
        <button 
          onClick={handleGoogleLogin} 
          className="google-button" 
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <div className="spinner">
              <div className="spin-dot"></div>
            </div>
          ) : (
            <>
              <img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" alt="Google Logo" />
              Continue With Google
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default Authentication;
