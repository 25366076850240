import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { doc, setDoc, updateDoc, getDocs, query, collection, where } from 'firebase/firestore';
import { firestore, auth, googleProvider } from '../firebase';
import { signInWithPopup } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import appConfig from '../config/appConfig.json';
import { useDebounce } from 'use-debounce';
import ReactMarkdown from 'react-markdown';

import './UserDetailsInterface.css';
import { createApi } from '@reduxjs/toolkit/query';

const UserDetailsInterface = () => {
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({ name: '', dob: '', time: '', location: '' });
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [step, setStep] = useState(0);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [predictions, setPredictions] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [debouncedLocation] = useDebounce(input, 300);
  const chatWindowRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [locationSelected, setLocationSelected] = useState(false);
  const [isLocationSent, setIsLocationSent] = useState(false);
  const [isInputEnabled, setIsInputEnabled] = useState(false);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const [hasNameSent, setHasNameSent] = useState(false);
  const [pendingQuestion, setPendingQuestion] = useState('');
  const [referralCode, setReferralCode] = useState(''); // State for referral code
  // const [showSignupPopup, setShowSignupPopup] = useState(false);
  
  const navigate = useNavigate();
  const userId = uuidv4();
  const [user] = useAuthState(auth);

  const questions = [
    { id: 1, text: "🙏 Namaste! To answer your questions accurately, may I have your name to get started?" },
    { id: 2, text: `Thank you, ${userDetails.name}! That’s a lovely name. Please provide your date of birth.` },
    { id: 3, text: "Could you please provide your time of birth?" },
    { id: 4, text: "We're almost there! Tell me where you were born?" },
  ];

  const formatDate = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const fetchAstrologyDetails = async (userData) => {
    try {
      const response = await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/api/astrology-details`, userData);
      if (response.status === 200) {
        const astroData = response.data;
        const updatedUserDetails = {
          ...userDetails,
          astro_data: astroData,
        };
        setUserDetails(updatedUserDetails);
  
        // Update Firestore with astrology data
        const userRef = doc(firestore, 'users', userId);
        await updateDoc(userRef, { astro_data: astroData });
        addMessage("bot", "Look's great! Let’s see what secrets the stars reveal just for you...");
        setIsTyping(true);
        // Pass the updated user details with astro data to fetchUserSummary
        // console.log("details passed to get summary function",updatedUserDetails);
        setTimeout(() => fetchUserSummary(updatedUserDetails), 5000);
      }
    } catch (error) {
      console.error("Error fetching astrology details:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: 'Error fetching astrology details. Please try again.', sender: 'bot' },
      ]);
    }
  };
  
  // Function to fetch user summary from backend
  const fetchUserSummary = async (userData) => {
    // console.log("user data from fetchUser Summary function", userData);
    setIsTyping(true);
    try {
      const response = await axios.post(`${appConfig.REACT_APP_BACKEND_URL}/chat/get-user-summary`, userData);
      if (response.status === 200) {
        const summary = response.data.summary;
        addMessage("bot", summary); // Display summary to user
      }
    } catch (error) {
      console.error("Error fetching user summary:", error);
      addMessage("bot", "Error generating your astrology summary. Please try again later.");
    } finally {
      setIsTyping(false);
      setIsInputEnabled(true);
      setStep(questions.length); 
    }
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (step < questions.length) {
      setIsTyping(true);
      setTimeout(() => {
        setIsTyping(false);
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: questions[step].text, inputType: questions[step].type, placeholder: questions[step].placeholder, time: new Date().toLocaleTimeString() }
        ]);
      }, 1000);
    }
  }, [step]);

  useEffect(() => {
    if (debouncedLocation && step === 3 && !locationSelected) {
      fetchLocationPredictions(debouncedLocation);
    }
  }, [debouncedLocation, locationSelected]);
  
  useEffect(() => {
    if (predictions.length > 0 && chatWindowRef.current) {
      // Delay scroll to ensure it captures rapid updates in predictions
      const scrollTimeout = setTimeout(() => {
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }, 100); // Adjust the delay if necessary
  
      return () => clearTimeout(scrollTimeout); // Clean up on component unmount or re-render
    }
  }, [predictions]);

  const addMessage = (sender, text) => {
    setMessages((prevMessages) => [...prevMessages, { sender, text, time: new Date().toLocaleTimeString() }]);
  };

  // Function to add an input field below each question
  const addInputField = (step) => {
    const question = questions[step];
    const isLocationStep = step === 3;
    
    return (
      <div className="chat-input-container">
        <div className='chat-input'>
          <input
            type={isLocationStep ? 'text' : step === 1 ? 'date' : step === 2 ? 'time' : 'text'}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent the default action (optional)
                  handleUserResponse(input); // Send input when Enter is pressed
              }
            }}
            placeholder={isLocationStep ? "Type your birth location..." : step === 0 ? "Enter Full Name. E.g. Krishna Kumar" : step === 1 ? "Enter your Date of Birth" : "Enter your Time of Birth"}
            onFocus={(e) => {
              if (step === 1) {
                  e.target.showPicker(); // Automatically show the date picker on focus
              }
            }}
          />
          {predictions.length > 0 || loading ? (
              <ul className="predictions">
                {loading ? (
                  <li>Finding location...</li>
                ) : (
                  predictions.map((prediction) => (
                    <li key={prediction.place_id} onClick={() => handlePredictionClick(prediction)}>
                      {prediction.name}
                    </li>
                  ))
                )}
              </ul>
            ) : null}
            <button onClick={() => handleUserResponse(input)} >
              <i className="ri-send-plane-fill"></i>
            </button>
        </div>
        {/* Show the "Or Continue" button only if on name step and name hasn't been sent */}
        {step === 0 && !hasNameSent && (
          <div className="or-continue">
            <span>or</span>
            <p onClick={handleGoogleLogin} className="continue-button">
              Login to continue
            </p>
          </div>
        )}
      </div>
    );
  };

  const generateReferralCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let code = '';
    for (let i = 0; i < 6; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return code;
  };

  const handleUserResponse = (value) => {
    if (step === 0 && input.trim()) {
      setHasNameSent(true); // Mark name as sent
    }
    if (value.trim() === '') {
      setErrorMessage('This field cannot be empty.');
      return;
    }
    setErrorMessage('');
    if (step >= questions.length) {
      setPendingQuestion(value);
      handleQuestionSubmission();
      addMessage("user", value);
      setInput('');
      return;
    }

    const newDetails = { ...userDetails };
    if (step === 0) newDetails.name = value;
    else if (step === 1) newDetails.dob = value;
    else if (step === 2) newDetails.time = value;
    else if (step === 3) {
      if (!newDetails.location || !newDetails.lat || !newDetails.lon) {
        setErrorMessage('Please select a valid location from the list.');
        return;
      }
      newDetails.uid = userId;
      setUserDetails(newDetails);
      addMessage("user", value);
      saveUserData();
      setInput('');
      // inputRef.current.focus();
      setLocationSelected(false);
      setIsInputEnabled(false);
      setIsLocationSent(true);
      return;
    }

    setUserDetails(newDetails);
    addMessage("user", value);
    setInput('');
    setStep(step + 1);
  };

  const fetchLocationPredictions = async (input) => {
    setLoading(true);
    try {
      const response = await axios.get(`${appConfig.REACT_APP_BACKEND_URL}/geocode/autocomplete`, {
        params: { text: input }
      });

      // console.log(response.data);

      if (response.data) {
        setPredictions(response.data);
      } else {
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching location predictions:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveUserData = async () => {
    if (!userDetails.name || !userDetails.dob || !userDetails.time || !userDetails.location) {
        addMessage("bot", "Please fill out all required fields.");
        return;
    }
    try {
        // Save the user details with a UUID if not authenticated
        const userRef = doc(firestore, 'users', userId);
        await setDoc(userRef, {
            ...userDetails,
            uid: userId, // Ensure to save the generated UUID here
            createdAt: formatDate(),
            RemainingchatCount: 5
        });
        fetchAstrologyDetails(userDetails);
        navigate('/chat');
    } catch (error) {
        console.error('Error saving user data:', error);
        addMessage("bot", "There was an issue saving your information. Please try again.");
    }
  };

  const handleGoogleSignup = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        const uid = user.uid; // Get the UID from the signed-in user
        
        // const oldUserRef = doc(firestore, 'users', userId);
        const newUserRef = doc(firestore, 'users', uid);
        
        // Generate a referral code for the new user
        const userReferralCode = generateReferralCode();
        
        await setDoc(newUserRef, {
          uid,
          RemainingchatCount: 5,
          name: user.displayName,
          email: user.email,
          profilePicture: user.photoURL,
          referralCode: userReferralCode,
          referredBy: referralCode || null, // Save the referral code used (if any)
          rewards: referralCode ? 10 : 0,
          ...userDetails, // userDetails has the necessary fields
          pendingQuestion: pendingQuestion,
          createdAt: formatDate(),
        });

        if (referralCode) {
          const referrerQuery = query(
            collection(firestore, 'users'),
            where('referralCode', '==', referralCode)
          );
          const referrerSnapshot = await getDocs(referrerQuery);
    
          if (!referrerSnapshot.empty) {
            const referrerDoc = referrerSnapshot.docs[0];
            const referrerRef = referrerDoc.ref;
    
            // Update referrer's rewards
            await updateDoc(referrerRef, {
              rewards: (referrerDoc.data().rewards || 0) + 20, // Example: Reward referrer with 20 points
            });
          } else {
            console.log('Invalid referral code.');
          }
        }

        setTimeout(() => {
          navigate('/chat?signup=complete'); // Redirect to chat after delay
        }, 1500); // Redirect to chat after successful login  
    } catch (error) {
        console.error('Error signing in with Google:', error);
        alert('Error signing in with Google');
    } finally {
        setShowSignupPopup(false);
    }
  };

  const handleGoogleLogin = async () => {
    // setButtonLoading(true);
    try {
      await signInWithPopup(auth, googleProvider);
      navigate('/form?login=complete'); // Redirect to chat after successful login
    } catch (error) {
      console.error('Error signing in with Google:', error);
      alert('Error signing in with Google');
    } finally {
      // setButtonLoading(false);
    }
  };

  const handleQuestionSubmission = () => {
    // Show the signup popup after the question is submitted
    setShowSignupPopup(true);
  };

  const handlePredictionClick = (prediction) => {
    setInput(prediction.name);
    setLat(prediction.lat);
    setLon(prediction.lon);
    setPredictions([]);
    setLocationSelected(true);
  
    setUserDetails(prevDetails => ({
      ...prevDetails,
      location: prediction.name,
      lat: prediction.lat,
      lon: prediction.lon,
    }));
  };

  
  return (
    <div className="chat-container">
      <div className="top-section">
        <div className="top-section-content">
          <div className="profile-pic-container">
            <img src="astro.png" alt="Astrologer" className="profile-pic" />
            <div className="status-dot"></div>
          </div>
          <div className="astrologer-info">
            <h4>Acharya Raghavendra</h4>
          </div>
        </div>
      </div>

      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.sender}`}>
            <div className="message-content">
              <ReactMarkdown>{msg.text}</ReactMarkdown>
              <div className="timestamp">{msg.time}</div>
            </div>
          </div>
        ))}
        {isTyping && (
          <div className="typing-indicator">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}
        {step < questions.length && (
          <div className="input-field">
            {/* Only render the input field for the location step if it hasn't been sent yet */}
            {(step !== 3 || !isLocationSent) && addInputField(step)}
          </div>
        )}
        {isInputEnabled && (
          <div className="chat-input">
            <input
              type="text"
              placeholder={questions[step]?.placeholder || 'Ask your question...'}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleUserResponse(input)}
            />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button onClick={() => handleUserResponse(input)} ><i className="ri-send-plane-fill"></i></button>
          </div>
        )}
        {showSignupPopup && (
          <>
            <div className="overlay" onClick={() => setShowSignupPopup(false)}></div>
            <div className="signup-popup">
              <p onClick={() => setShowSignupPopup(false)} className="cancel"><i class="ri-close-large-line"></i></p>
              <p><b>Sign up with Google to unlock free chats</b></p>
              <div className="astro-image"></div>
              <button onClick={handleGoogleSignup}><img src="https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png" alt="Google Logo" className='logo' />Continue with Google</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserDetailsInterface;
