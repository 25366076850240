import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { firestore } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import './UserForm.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import Footer from './Footer.js';
import appConfig from '../config/appConfig.json'

const UserForm = () => {
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [time, setTime] = useState('');
  const [location, setLocation] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [debouncedLocation] = useDebounce(location, 500);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userData = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (userData && userData.dob && userData.time && userData.location) {
      navigate('/chat?login=complete');
    }
  }, [userData, navigate]);

  useEffect(() => {
    if (debouncedLocation) {
      fetchPredictions(debouncedLocation);
    }
  }, [debouncedLocation]);

  const fetchPredictions = async (input) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${appConfig.REACT_APP_BACKEND_URL}/geocode/autocomplete`, {
        params: { text: input }
      });

      console.log(response.data); // Logging the predictions

      if (response.data) {
        setPredictions(response.data);
      } else {
        console.error('Unexpected response structure:', response.data);
      }
    } catch (error) {
      console.error('Error fetching location predictions:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleLocationChange = (e) => {
    const input = e.target.value;
    setLocation(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');

    if (!user) {
      setErrorMessage('User not authenticated. Please log in.');
      navigate('/login');
      return;
    }

    if (!dob || !time || lat === null || lon === null) {
      setErrorMessage('Please fill in the date, time of birth, and select a location.');
      return;
    }

    const userData = { name, dob, time, location, lat, lon };

    try {
      const userRef = doc(firestore, 'users', user.uid);
      await setDoc(userRef, {
        ...userData,
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
      });
      navigate('/chat?login=complete');
      window.location.reload();
    } catch (error) {
      console.error('Error saving user data:', error);
      setErrorMessage('Error saving user data. Please try again.');
    }
  };

  const handlePredictionClick = (prediction) => {
    setLocation(prediction.name);
    setLat(prediction.lat);
    setLon(prediction.lon);
    setPredictions([]);
  };

  return (
    <>
      <div className="form-container">
        <h1>Destiny awaits...</h1>
        <form onSubmit={handleSubmit}>
          <div className='form-left'>
            <h2>Enter Your Details</h2>
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <label>Name:</label>
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />

            <label>Date of Birth:</label>
            <input type="date" value={dob} onChange={(e) => setDob(e.target.value)} required />

            <label>Time of Birth:</label>
            <input type="time" value={time} onChange={(e) => setTime(e.target.value)} required />

            <label>Location of Birth:</label>
            <input
              type="text"
              value={location}
              onChange={handleLocationChange}
              required
            />

            {/* Display location predictions */}
            {predictions.length > 0 || loading ? (
              <ul className="userForm-predictions">
                {loading ? (
                  <li>Finding location...</li>
                ) : (
                  predictions.map((prediction) => (
                    <li key={prediction.place_id} onClick={() => handlePredictionClick(prediction)}>
                      {prediction.name}
                    </li>
                  ))
                )}
              </ul>
            ) : null}

            <button type="submit" className='btn'>Submit</button>
          </div>
          <hr />
          <div className='form-right'>
            <img src="https://bestjyotish.com/images/02309121104459489.png" alt='astro'></img>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default UserForm;
