import React, { useState } from "react";
import "./Testimonial.css";

const testimonials = [
  {
    name: "Amit Kumar",
    text: "meri zindagi ko badalne mein madad ki! Jo insights mile, woh bilkul sahi aur kaafi madadgar thi.",
    image: "https://avatar.iran.liara.run/public?username=Jordan",
  },
  {
    name: " Vinit Singh",
    text: " Bahut hi accha aur hopeful jawab mila! Astrology ke insights se clear hua ki mid-2025 tak railway job milne ke strong chances hain. Positive advice aur agle steps ke liye guidance bhi offer kiya gaya. Bohot helpful aur encouraging!",
    image: "https://avatar.iran.liara.run/public/8",
  },
  {
    name: "Abhishek Modi",
    text: " Upcoming financial opportunities ke bare me guide kiya. Saturn's retrograde ke impact ka dhyan rakhte hue practical tips diye. Bohot acha.",
    image: "https://avatar.iran.liara.run/public/12",
  },
  {
    name: "Sitesh Sarkar",
    text: " Mere emotions ko samjha aur meri situation ko astrology ke through explain kiya, jo kaafi relatable laga. Self-care aur positivity ke liye helpful advice mili. Thank you.",
    image: "https://avatar.iran.liara.run/public/10",
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="testimonial-slider">
      <div
        className="slider"
        style={{
          transform: `translateX(-${currentIndex * 100}%)`,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <div className="slide" key={index}>
            <img src={testimonial.image} alt={testimonial.name} />
            <p className="text">"{testimonial.text}"</p>
            <h3 className="name">- {testimonial.name}</h3>
          </div>
        ))}
      </div>
      <button className="control prev" onClick={prevSlide}>
        &lt;
      </button>
      <button className="control next" onClick={nextSlide}>
        &gt;
      </button>
    </div>
  );
};

export default Testimonial;
