import React, { useState } from 'react';
// import './VarshphalReport.css';
import Nav from './Nav.js';
import Footer from './Footer.js';
import { useNavigate } from 'react-router-dom';
// import TrackingStatus from './TrackingStatus.js';
import Testimonial from './Testimonial.js';

const VarshphalReportLandingPage
 = () => {
    const [inputValue, setInputValue] = useState(''); // State to hold the input value
    const [reportId, setReportId] = useState(null); // State to hold the report ID after button click
    const navigate = useNavigate();

    const cards = [
        {
          icon: '💔',
          title: 'Relationship Problems',
          description: 'Are you struggling to develop & maintain healthy relationships with your family and loved ones? Know about your life partner in your kundli report.',
        },
        {
          icon: '💼',
          title: 'Business Problems',
          description: 'Struggling to achieve business goals? Overcome Losses, Financial Challenges. Earn Money with your finance analysis details in your online janam kundli report.',
        },
        {
          icon: '₹',
          title: 'Financial Troubles',
          description: 'Struggling to make ends meet due to financial troubles? Unable to save your hard-earned money? Discover effective solutions in a detailed kundli & financial analysis report by time & date of birth.',
        },
        {
          icon: '❤️',
          title: 'Love Troubles',
          description: 'Facing a lack of love from your partner? Is your partner losing interest in you? Feeling unloved & unhappy in your relationship? Get expert insights in kundli report.',
        },
        {
          icon: '🎓',
          title: 'Career Prospects',
          description: 'Facing challenges in getting your dream job? Struggling with government job exams? Low grades? Get a complete career analysis to ace exams & secure jobs, even abroad with an online janam kundli report.',
        },
        {
          icon: '🌟',
          title: 'Home And Luxury',
          description: 'Longing for a luxurious lifestyle? Have troubles at home & neighborhood? Unable to build a new home & life? Get solutions & in-depth analysis in your kundli report.',
        },
    ];
    const features = [
        {
          title: "Dosh & Dasha's Analysis",
          description: "Detailed report of doshas – Kuja (Mangal), Shani (Saturn), Rahu-Ketu, Dasha & remedies, from mantras to rituals.",
        },
        {
          title: "All Yog Report",
          description: "Know about the yogs that are formed in your kundali—Hana, Raja, Graha Maitri, Budhaditya, and Sri Yog. Unlock wealth, career growth, relationships, and intellect.",
        },
        {
          title: "Gemstone Recommendation",
          description: "When, Why, & How to wear gems by the best gemologist in India. Get personalized gemstone recommendations based on your kundli report.",
        },
        {
          title: "Puja Recommendation",
          description: "Puja’s to do suggestions by studying Kundli's dosha remedies. The rituals are designed to balance life & bring positivity, based on grahas.",
        },
        {
          title: "Ank Jyotish Recommendation",
          description: "A numerological analysis of your numbers gives out details of your personality, nature, and behaviors.",
        },
        {
          title: "Monthly Prediction For 1 Year",
          description: "Know what this year has got for you through a detailed report of your monthly horoscope.",
        },
        {
          title: "Yearly Prediction For 10 Years",
          description: "Know what this decade has got for you through a detailed report of your monthly horoscope.",
        },
        {
          title: "Access To Monthly Plan",
          description: "You will get one month plan for free. Where you will get 1000 chats free",
        },
    ];
    const statusUpdates = [
        { date: "Jan 08", time: "10:54 am", status: "Delivered" },
        { date: "Jan 05", time: "16:00 pm", status: "Final Collection Reminder" },
        { date: "Jan 03", time: "12:12 pm", status: "Ready For Collection" },
    ];

    // Function to handle navigation
    // const handleClick = (route) => {
    //     navigate(`/report/${route}/payment-page`);
    // };

    // Function to handle the "CHECK REPORT STATUS" button click
    const handleCheckStatus = () => {
        setReportId(inputValue); // Update reportId with the current input value
    };

    return (
      <>
          <Nav />
          
          <div className='report-page'>
              <img src="/astro.png" alt="Astrologer" className="hero-image" />
              <h1 className='report-heading'>Discover Your Upcoming Years with Online Varshaphal!</h1>
              <p className='report-text'>Discover life-changing secrets: Your Complete Astro Profile awaits!</p>
              <button className='report-cta' onClick={() => navigate('/report/varshaphal-report/payment-page')}>Get Varshaphal Report</button>
              <img src='/book.png' alt='book' className='life-report-book'></img>
          </div>
          
          <div className='report-second-page'>
              <div className="report-card-container">
                  {cards.map((card, index) => (
                      <div key={index} className="report-card">
                          <div className="report-card-icon">{card.icon}</div>
                          <h3 className='report-card-title'>{card.title}</h3>
                          <p className='report-card-text'>{card.description}</p>
                      </div>
                  ))}
              </div>
          </div>
          
          <div className='report-third-page'>
              <div className="feature-card-section">
                  <h2 className='feature-card-section-title'>What Will You Get In Your Personalized Online Life Report</h2>
                  <div className="feature-card-grid">
                      {features.map((feature, index) => (
                      <div className="feature-card" key={index}>
                          <h3 className='feature-card-title'>{feature.title}</h3>
                          <div className='feature-card-text'><p>{feature.description}</p></div>
                      </div>
                      ))}
                  </div>
                  
              </div>
          </div>
          
          <div className='pricing-page'>
            <h1 className='pricing-page-title'>At What Pricing We Offer ?</h1>
            <div className="pricing-section">
              <p>
                <span className="old-price">₹1199</span> <span className="new-price">₹599</span> - Only
              </p>
              <ul>
                <li>Year Prediction</li>
                <li>Lowest Rates In The Market</li>
                <li>100 Percent Success Rate</li>
              </ul>
              <p className="note">Note: We apologize to inform you that the delivery time for Kundali orders has been extended from 2 to 4 days. We appreciate your patience and understanding during this adjustment period. Our team is working diligently to ensure timely and accurate delivery.</p>
              <button className="buy-button"  onClick={() => navigate('/report/varshaphal-report/payment-page')}>Buy Report Now</button>
            </div>
          </div>
          
          <div className='report-fourth-page'>
            <h1>Our Happy Costumers</h1>
            <Testimonial />
          </div>

          {/* <div className='report-fifth-page'>
              <h1>Track Your Report Status</h1>
              <p>Enter your report ID below to quickly check the current status of your report within few seconds.</p>
              <div className="container">
                  <input
                      type="text"
                      className="email-input"
                      placeholder="e.g. 8b61e2ce-31ba-4615-b5b8-6d65dbc0c9bb"
                      value={inputValue}
                      onChange={(e) => setInputValue(e.target.value)} // Update inputValue as the user types
                  />
                  <button className="download-button" onClick={handleCheckStatus}>
                      CHECK REPORT STATUS
                  </button>
              </div>
              {reportId && ( // Only render TrackingStatus if reportId is not null
                  <TrackingStatus trackingRef={reportId} statusUpdates={statusUpdates} />
              )}
          </div> */}
          
          <Footer />
      </>
    );
};

export default VarshphalReportLandingPage
;
